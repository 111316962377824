.separator {
  background: #454545;
  border: 0;
  margin-bottom: 2.4em;
  height: 1px;
  max-width: 70%;
}

@media screen and (min-width: 30em) {
  .separator {
    margin-bottom: 3.31em;
  }
}

@media screen and (min-width: 56.25em) {
  .separator {
    flex: 0 0 50%;
  }
  .separator {
    margin-bottom: 4em;
  }
}

@media screen and (min-width: 75em) {
  .separator {
    margin-bottom: 6em;
  }
}

@media screen and (min-width: 100em) {
  .separator {
    margin-bottom: 6em;
  }
}
