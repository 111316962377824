.card {
  font-size: 1.4rem;
  text-align: center;
  position: relative;
  padding: 0.5em 0.5em;
}

.card__line {
  width: 5em;
  height: 0.25em;
  margin: 0.5em auto;
  display: inline-block;
  background: #ea0026 !important;
}
.card__content {
  text-align: center;
}

.card__title {
  color: white;
  font-size: 1rem;
  font-weight: 700;
  margin: 1em 0;
}

.card__paragraph {
  color: #b0b0b0;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.1em;
  word-break: word-break;
}

@media screen and (min-width: 30em) {
  .card__title {
    font-size: 2.25rem;
  }

  .card__paragraph {
    padding: 1em 0;
  }
}

@media screen and (min-width: 56.25em) {
  .card__title {
    font-size: 2.25rem;
  }
}

@media screen and (min-width: 75em) {
  .card__title {
    font-size: 3.25rem;
  }
}

@media screen and (min-width: 100em) {
  .card__title {
    font-size: 3.25rem;
  }

  .card__line {
      width: 7em;
  }
}
