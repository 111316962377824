.service-list {
  font-size: 1em;
  font-weight: 500;
  line-height: 1.2em;
  margin: 0 auto;
  text-align: center;
}
.service-list__item {
  float: none;
}

@media screen and (min-width: 30em) {
  .service-list {
    font-size: 1.2em;
    line-height: 1.2em;
  }
}

@media screen and (min-width: 56.25em) {
  .service-list {
    font-size: 1.4em;
    line-height: 1.5em;
  }
  .service-list__item {
    float: left;
    width: 33.33333%;
  }
}

@media screen and (min-width: 75em) {
}

@media screen and (min-width: 100em) {
}
