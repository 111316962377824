.footer {
  color: white;
  background: #000000;
  padding: 2em 0 2em;
  position: relative;
  border-bottom: solid 0.25em #ea0026;
}

.footer__link {
  color: #ea0026;
}

.footer__content {
  line-height: 1.5em;
}

@media screen and (min-width: 30em) {
  .footer {
    color: white;
    background: #000000;
    padding: 3em 0 3em;
    position: relative;
    border-bottom: solid 0.25em #ea0026;
  }
}

@media screen and (min-width: 56.25em) {
}

@media screen and (min-width: 75em) {
}

@media screen and (min-width: 100em) {
}
