.section {
  margin-bottom: 1em;
  margin-top: 1em;
  flex: 1;
  position: relative;;
}

.section:first-of-type {
  padding-top: 1rem;
  flex: 1;
  margin-bottom: 1rem;
}

.section:last-of-type {
  margin-bottom: 1rem;
  flex: 1;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 30em) {
  .section:first-of-type {
    padding-top: 2rem;
  }
  .section:last-of-type {
    margin-bottom: 5.6rem;
  }
}

@media screen and (min-width: 56.25em) {
}

@media screen and (min-width: 75em) {
  .section:last-of-type {
    margin-bottom: 8.5rem;
  }
}

@media screen and (min-width: 100em) {

}
