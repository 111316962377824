.client-list {
  font-size: 1em;
  font-weight: 500;
  line-height: 1.2em;
  margin: 0 auto;
  text-align: center;
}
.client-list__item {
  float: none;
}

@media screen and (min-width: 30em) {
  .client-list {
    font-size: 1.2em;
    line-height: 1.5em;
  }
  .client-list__item {
    float: left;
  }
}

@media screen and (min-width: 56.25em) {
  .client-list {
    font-size: 1.4em;
    line-height: 1.5em;
  }
}

@media screen and (min-width: 75em) {
}

@media screen and (min-width: 100em) {
}
