.section__header {
  text-align: center;
  font-weight: 700;
  margin: 1em 0;
  font-size: 1.4rem;
}

@media screen and (min-width: 30em) {
  .section__header {
    font-size: 2.25rem;
  }
}

@media screen and (min-width: 75em) {
  .section__header {
    font-size: 3.25rem;
  }
}

@media screen and (min-width: 100em) {
  .section__header {
    font-size: 3.25rem;
    margin: 1.5em 0;
  }
}
