.banner {
  color: white;
  background-position: center;
  background-size: cover;
  position: relative;
}

.banner__content {
  margin-left: auto;
  margin-right: auto;
  max-width: 28.125em;
  text-align: center;
}

.banner__header {
  font-size: 3em;
  font-weight: 900;
}

.banner__header--red {
  color: #ea0026;
}

.banner__intro {
  padding: 1em;
}

.banner__intro_title {
  font-size: 1.4em;
  font-weight: 700;
  margin: 1em 0;
}

.banner_intro_paragraph {
  font-size: 1em;
  font-weight: 500;
  line-height: 1.15em;
  color: #b0b0b0;
  white-space: break-spaces;
}

@media screen and (min-width: 30em) {
  .banner__header {
    font-size: 5em;
  }

  .banner__content {
    max-width: 38.125em;
  }

  .banner__intro_title {
    font-size: 2.25rem;
  }

  .banner_intro_paragraph {
    font-size: 1.2em;
    line-height: 1.5em;
  }
}

@media screen and (min-width: 56.25em) {
  .banner_intro_paragraph {
    font-size: 1.4em;
    line-height: 1.5em;
  }
}

@media screen and (min-width: 75em) {
  .banner__intro_title {
    font-size: 3.25rem;
  }

  .banner__header {
    font-size: 9em;
  }
  .banner__content {
    max-width: 50em;
  }

  .banner_intro_paragraph {
    font-size: 1.4em;
    line-height: 1.5em;
  }
}

@media screen and (min-width: 100em) {
}
